<template>
  <div>
    <section class="text-idBlued">
      <h5>SECTION A:</h5>
      <h2>NEW STAFF BIO-DATA</h2>
    </section>

    <form @submit.prevent="proceed">
      <div>
        <label for="title"> Title<span class="req">*</span> </label>
        <v-select
          id="title"
          label="title"
          :reduce="(b) => b.title"
          :options="$store.state.staff.titles"
          v-uppercase
          v-model="form.title"
          placeholder="-- select title --"
        ></v-select>
      </div>

      <div>
        <label for="lastName"> Last Name<span class="req">*</span> </label>
        <input
          id="lastName"
          type="text"
          v-model="form.lastName"
          class="input uppercase"
        />
      </div>

      <div>
        <label for="firstName"> First Name<span class="req">*</span> </label>
        <input
          id="firstName"
          type="text"
          v-uppercase
          v-model="form.firstName"
          class="input uppercase"
        />
      </div>

      <div>
        <label for="middleName"> Middle Name<span class="req">*</span> </label>
        <input
          id="middleName"
          type="text"
          v-uppercase
          v-model="form.middleName"
          class="input uppercase"
        />
        <small
          v-if="form.middleName && !middleNameReady"
          class="text-red-500 text-xs"
        >
          Make sure you enter the middle name in full, no abbreviations or
          initials
        </small>
      </div>

      <!-- <div>
        <label for="email"> Email Address<span class="req">*</span> </label>
        <input
          id="email"
          type="email"
          v-model="form.email"
          class="input lowercase"
        />
      </div> -->

      <div>
        <label for="dateOfBirth">
          Date Of Birth<span class="req">*</span>
        </label>
        <input
          id="dateOfBirth"
          type="date"
          v-model="form.dateOfBirth"
          class="input uppercase"
        />
      </div>

      <div>
        <label for="gender"> Gender<span class="req">*</span> </label>
        <v-select
          id="gender"
          label="label"
          :reduce="(b) => b.value"
          :options="genders"
          v-uppercase
          v-model="form.gender"
          placeholder="-- select --"
        ></v-select>
      </div>

      <div>
        <label for="nationality"> Nationality<span class="req">*</span> </label>
        <v-select
          id="nationality"
          label="countryName"
          :reduce="(b) => b.countryName"
          :options="$store.state.staff.countries"
          v-uppercase
          v-model="form.nationality"
          placeholder="-- select --"
        ></v-select>
      </div>

      <div>
        <label for="stateOfOrigin">
          State of Origin<span class="req">*</span>
        </label>
        <v-select
          id="stateOfOrigin"
          label="stateName"
          :reduce="(b) => b"
          :options="$store.state.staff.states"
          v-uppercase
          v-model="form.stateOfOrigin"
          placeholder="-- select --"
        ></v-select>
      </div>

      <div>
        <label for="lga"> LGA<span class="req">*</span> </label>
        <v-select
          id="lga"
          label="lgaName"
          :reduce="(b) => b"
          :options="lgas"
          v-uppercase
          v-model="form.lga"
          placeholder="-- select --"
          :disabled="!form.stateOfOrigin"
        ></v-select>
      </div>

      <div>
        <label for="city"> Town<span class="req">*</span> </label>
        <v-select
          id="city"
          label="cityName"
          :reduce="(b) => b.cityName"
          :options="cities"
          v-uppercase
          v-model="form.city"
          placeholder="-- select --"
          :disabled="!form.lga"
        ></v-select>
      </div>

      <div>
        <button :disabled="proceeding || !formReady" type="submit">
          <span>Save and Proceed</span>
          <spinner v-if="proceeding"></spinner>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { fetchLgas, fetchCities } from "@/services/sourceData";
export default {
  name: "NewStaffBioData",

  data() {
    return {
      proceeding: false,
      form: {
        title: this.$store.state.admin?.newStaff?.title,
        lastName: this.$store.state.admin?.newStaff?.lastName,
        firstName: this.$store.state.admin?.newStaff?.firstName,
        middleName: this.$store.state.admin?.newStaff?.middleName,
        // email: this.$store.state.admin?.newStaff?.email,
        dateOfBirth: this.$store.state.admin?.newStaff?.dateOfBirth,
        gender: this.$store.state.admin?.newStaff?.gender,
        nationality: this.$store.state.admin?.newStaff?.nationality,
        stateOfOrigin: this.$store.state.admin?.newStaff?.stateOfOrigin,
        lga: this.$store.state.admin?.newStaff?.lga,
        city: this.$store.state.admin?.newStaff?.city,
      },
      genders: [
        { label: "Female", value: "F" },
        { label: "MALE", value: "M" },
      ],
      lgas: [],
      cities: [],
    };
  },

  async created() {
    await this.$store.dispatch("staff/getTitles");
    await this.$store.dispatch("staff/getCountries");
    await this.$store.dispatch("staff/getStates");
  },

  computed: {
    middleNameReady() {
      return Boolean(this.form.middleName) && this.form.middleName.length >= 3;
    },
    formReady() {
      let ready = true;
      if (!this.middleNameReady) return false;

      for (const fd in this.form) {
        if (Object.hasOwnProperty.call(this.form, fd)) {
          if (
            !this.form[fd] ||
            (typeof this.form[fd] === "string" && this.form[fd].trim() === "")
          ) {
            ready = false;
          }
        }
      }

      return ready;
    },
  },

  watch: {
    async "form.stateOfOrigin"(nv) {
      if (nv) {
        this.lgas = await fetchLgas(this.form.stateOfOrigin.stateCode);
      }
    },
    async "form.lga"(nv) {
      if (nv) {
        this.cities = await fetchCities(this.form.lga.lgaCode);
      }
    },
  },

  methods: {
    async proceed() {
      if (!this.formReady) {
        this.$swal({
          icon: "error",
          text: "Fill all fiields",
        });
        return;
      }

      try {
        this.proceeding = true;

        this.form.stateOfOrigin = this.form.stateOfOrigin.stateName;
        this.form.lga = this.form.lga.lgaName;

        this.$store.commit("admin/saveNewStaff", {
          ...this.form,
          isRequested: false,
          isCreated: false,
        });

        this.proceeding = false;

        this.$router.push({ name: "New Staff Employment Data" });
      } catch (error) {
        this.proceeding = false;
      }
    },
  },
};
</script>

<style scoped>
h5 {
  @apply text-xs lg:text-base font-bold uppercase;
}
h2 {
  @apply text-xl lg:text-3pxl uppercase;
}
form {
  @apply bg-litergray rounded-lg p-6 mt-7;
}
form > div:not(:first-child) {
  @apply mt-6;
}
.req {
  @apply text-red-400;
}
form button[type="submit"] {
  @apply w-full bg-oneGreen text-white font-semibold py-2.5 rounded-lg flex justify-center items-center gap-2;
}
input {
  @apply uppercase;
}
</style>
