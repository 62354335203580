<template>
  <div class="relative">
    <section>
      <HorizontalNav :navs="tabs" :disabled="false" />
    </section>

    <section id="holder" class="w-full max-w-[700px] mx-auto px-4 lg:px-0">
      <router-view></router-view>
    </section>
  </div>
</template>

<script>
import HorizontalNav from "@/components/staff/verification/HorizontalNav.vue";
export default {
  name: "CreateStaff",

  components: {
    HorizontalNav,
  },

  data() {
    return {
      tabs: [
        {
          title: "Bio Data",
          link: "New Staff Bio Data",
        },
        {
          title: "Employment Data",
          link: "New Staff Employment Data",
        },
        {
          title: "Bank Verification",
          link: "New Staff Bank Verification",
        },
        {
          title: "Summary",
          link: "New Staff Summary",
        },
      ],
    };
  },
};
</script>

<style scoped>
section#holder {
  @apply h-auto overflow-y-scroll;
  height: calc(100vh - 200px);
}
</style>
