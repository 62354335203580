<template>
  <div>
    <section class="text-idBlued">
      <h5>SECTION E:</h5>
      <h2>NEW STAFF Bank Info</h2>
    </section>

    <form @submit.prevent="proceed">
      <!-- <div>
        <label for="bankType"> Bank Name<span class="req">*</span> </label>
        <v-select
          id="bankType"
          label="label"
          :reduce="(b) => b.value"
          :options="bankTypes"
          v-uppercase
          v-model="form.bankType"
          placeholder="-- select --"
        ></v-select>
      </div> -->

      <div>
        <label for="bankCode">Bank Name<span class="req">*</span> </label>
        <v-select
          id="bankCode"
          label="bankName"
          :reduce="(b) => b.bankCode"
          :options="$store.state.staff.banks"
          v-model="form.bankCode"
          placeholder="-- select --"
        ></v-select>
      </div>

      <div>
        <label for="salaryAccountNumber"
          >Account No<span class="req">*</span>
        </label>
        <input
          id="salaryAccountNumber"
          type="text"
          v-model="form.salaryAccountNumber"
          class="input"
          maxlength="10"
        />
      </div>

      <div>
        <label for="salaryAccountName">
          Account Name<span class="req">*</span>
        </label>
        <input
          id="salaryAccountName"
          type="text"
          v-model="form.salaryAccountName"
          class="input"
          readonly
        />
      </div>

      <div class="flex justify-between">
        <p class="text-mdgray font-semibold text-sm w-2/3">
          Click on this button to validate your account name by choosing your
          bank from the drop down above.
        </p>

        <button
          :disabled="checking || !bankReady"
          class="font-medium text-white bg-idBlued rounded-lg px-5 py-1 flex justify-center items-center gap-2"
          @click="nubanCheck"
          type="button"
        >
          <spinner v-if="checking"></spinner>
          <span>Validate Account</span>
        </button>
      </div>

      <div class="flex justify-between gap-4">
        <button
          type="button"
          class="bg-gray-600 button"
          @click="$router.push({ name: 'New Staff Employment Data' })"
        >
          <span>Previous</span>
        </button>

        <button
          :disabled="proceeding || !formReady"
          type="submit"
          class="bg-oneGreen"
        >
          <span>Save and Proceed</span>
          <spinner v-if="proceeding"></spinner>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "NewStaffBankVerification",
  data() {
    return {
      proceeding: false,
      checking: false,
      form: {
        bankType: this.$store.state.admin?.newStaff?.bankType,
        bankCode: this.$store.state.admin?.newStaff?.bankCode,
        bankName: this.$store.state.admin?.newStaff?.bankName,
        salaryAccountNumber:
          this.$store.state.admin?.newStaff?.salaryAccountNumber,
        salaryAccountName: this.$store.state.admin?.newStaff?.salaryAccountName,
      },
      // bankTypes: [
      //   { label: "COMMERCIAL BANKS", value: "COMMERCIAL BANKS" },
      //   {
      //     label: "MICRO-FINANCE/AGRIC BANKS",
      //     value: "MICRO-FINANCE/AGRIC BANKS",
      //   },
      // ],
    };
  },

  computed: {
    bankReady() {
      return (
        Boolean(this.form.bankCode) &&
        Boolean(this.form.salaryAccountNumber) &&
        this.form.salaryAccountNumber.length === 10
      );
    },
    formReady() {
      return this.bankReady && this.form.salaryAccountName;
    },
  },

  watch: {
    "form.bankCode"(newValue) {
      const dt = this.$store.state.staff.banks.find(
        ({ bankCode }) => bankCode === newValue
      );
      if (dt) {
        this.form.bankName = dt.bankName;
        this.form.bankType = dt.bankType;
      }
    },
  },

  methods: {
    async nubanCheck() {
      if (!this.bankReady) {
        this.$swal({
          icon: "error",
          text: "Select a bank",
        });
        return;
      }

      try {
        this.checking = true;
        const res = await this.$http.post("/service-staff/validate-bank", {
          bankCode: this.form.bankCode,
          bankType: this.form.bankType,
          salaryAccountNumber: this.form.salaryAccountNumber,
        });

        this.checking = false;
        if (!res) {
          return;
        }

        const { data } = res;

        this.form.salaryAccountName = data.data;
      } catch (error) {
        this.checking = false;
      }
    },

    async proceed() {
      if (!this.formReady) {
        this.$swal({
          icon: "error",
          text: "Fill all fiields",
        });
        return;
      }

      try {
        this.proceeding = true;

        this.$store.commit("admin/saveNewStaff", this.form);

        this.proceeding = false;

        this.$router.push({ name: "New Staff Summary" });
      } catch (error) {
        this.proceeding = false;
      }
    },
  },
};
</script>

<style scoped>
h5 {
  @apply text-xs lg:text-base font-bold uppercase;
}
h2 {
  @apply text-xl lg:text-3pxl uppercase;
}
form {
  @apply bg-litergray rounded-lg p-6 mt-7;
}
form > div:not(:first-child) {
  @apply mt-6;
}
.req {
  @apply text-red-400;
}
form .button[type="button"],
form button[type="submit"] {
  @apply w-full text-white font-semibold py-2.5 rounded-lg flex justify-center items-center gap-2;
}
input {
  @apply uppercase;
}
</style>
