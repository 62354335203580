<template>
  <div>
    <section id="printoutt">
      <StaffSummary class="" />
    </section>

    <section class="section">
      <div v-if="!formReady && !staffCreated" class="flex items-center gap-3">
        <Icon icon="mdi:warning" class="w-7 h-7 text-yellow-500" />
        <small class="font-bold text-purple-700">
          Please fill all fields required to create a new staff, in order to
          proceed
        </small>
      </div>

      <div class="flex justify-between gap-4">
        <template v-if="staffCreated">
          <button
            type="button"
            class="border border-gray-700 text-gray-700 inline-block"
            @click="closeSummary"
          >
            <Icon icon="mdi:close" class="w-7 h-7" />
            <span>Close</span>
          </button>

          <button
            type="button"
            class="border border-gray-600 text-gray-700"
            @click="printOut"
          >
            <Icon icon="mdi:printer" class="w-7 h-7" />
            <span>Print</span>
          </button>

          <button
            v-if="!staffRequested"
            type="button"
            @click="request"
            :disabled="proceeding"
            class="bg-idBlued text-white"
          >
            <span>Request GEN 69</span>
            <spinner v-if="proceeding"></spinner>
          </button>
        </template>

        <template v-else>
          <button
            type="button"
            class="bg-gray-600 text-white"
            @click="$router.push({ name: 'New Staff Bank Verification' })"
          >
            <span>Previous</span>
          </button>

          <button
            type="button"
            class="bg-red-100 text-black"
            @click="clearForms"
          >
            <span>Cancel</span>
          </button>

          <button
            :disabled="proceeding || !formReady"
            type="submit"
            class="bg-oneGreen text-white"
            @click="proceed"
          >
            <span>Create New Staff</span>
            <spinner v-if="proceeding"></spinner>
          </button>
        </template>
      </div>
    </section>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue2";
import StaffSummary from "@/components/admin/staff/StaffSummary.vue";
export default {
  name: "NewStaffSummary",

  components: {
    StaffSummary,
    Icon,
  },

  data() {
    return {
      proceeding: false,
      // staffCreated: false,
      requiredForm: [
        "title",
        "lastName",
        "firstName",
        "middleName",
        // "email",
        "dateOfBirth",
        "gender",
        "nationality",
        "stateOfOrigin",
        "city",
        "dateOfAppointment",
        "cadre",
        "salaryGradeLevel",
        // "salaryStructure",
        "currentStep",
        "mdaId",
        "departmentId",
        "staffTypeId",
        "salaryAccountNumber",
        "salaryAccountName",
        "bankName",
        "bankCode",
        "bankType",
      ],
      form: { ...this.$store.state.admin.newStaff, staffId: null },
    };
  },

  computed: {
    formReady() {
      let ready = true;

      for (const fd of this.requiredForm) {
        if (!this.form[fd] || this.form[fd].trim() === "") {
          ready = false;
        }
      }

      return ready;
    },

    staffCreated() {
      return Boolean(this.$store.state.admin.newStaff?.isCreated);
    },
    staffRequested() {
      return Boolean(this.$store.state.admin.newStaff?.isRequested);
    },
  },

  beforeUnmount() {
    // this.$store.commit("admin/clearNewStaff");
  },

  methods: {
    async clearForms() {
      const rep = await this.$swal({
        icon: "question",
        text: "Are you sure you want to stop the creating of this staff? All records will be added will be lost",
        showDenyButton: true,
        confirmButtonText: "Proceed",
        denyButtonText: "No",
      });

      if (!rep.isConfirmed) {
        return;
      }

      this.$store.commit("admin/clearNewStaff");
      this.$router.push({ name: "New Staff Bio Data" });
    },

    async proceed() {
      if (!this.formReady) {
        this.$swal({
          icon: "error",
          text: "Please fill all fiields",
        });
        return;
      }
      try {
        const rep = await this.$swal({
          icon: "question",
          text: "Are you sure you want to create a staff with the records provided?",
          showDenyButton: true,
          confirmButtonText: "Proceed",
          denyButtonText: "No",
        });

        if (!rep.isConfirmed) {
          return;
        }

        this.proceeding = true;

        const res = await this.$http.post(
          "/service-staff/create-staff",
          this.form
        );

        this.proceeding = false;
        if (!res) {
          return;
        }

        const { data } = res;
        this.form.staffId = data.data;
        this.$swal({
          icon: "success",
          text: data.message,
        });

        this.$store.commit("admin/clearNewStaff");
        this.$store.commit("admin/saveNewStaff", { isCreated: true });
      } catch (error) {
        this.proceeding = false;
      }
    },

    async request() {
      if (!this.staffCreated) {
        this.$swal({
          icon: "error",
          text: "There is no staff for this action",
        });
        return;
      }
      try {
        const rep = await this.$swal({
          icon: "question",
          text: "You are about to request GEN69 for the staff?",
          showDenyButton: true,
          confirmButtonText: "Proceed",
          denyButtonText: "Cancel",
        });

        if (!rep.isConfirmed) {
          return;
        }

        this.proceeding = true;

        const res = await this.$http.post(
          `/service-staff/request-signatory/${this.form.staffId}`
        );

        this.proceeding = false;
        if (!res) {
          return;
        }

        const { data } = res;
        this.$swal({
          icon: "success",
          text: data.message,
        });

        this.$store.commit("admin/clearNewStaff");
        this.$store.commit("admin/saveNewStaff", {
          isCreated: true,
          isRequested: true,
        });
      } catch (error) {
        this.proceeding = false;
      }
    },

    printOut() {
      const mywindow = window.open("", "", "");
      const divContents = document.getElementById("printoutt").innerHTML;
      mywindow.document.write("<html><head><title></title>");
      mywindow.document.write(
        ' <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/tailwindcss/2.2.19/tailwind.min.css">'
      );

      mywindow.document.write("</head><body>");
      mywindow.document.write("<div style='margin-top:20px;'></div>");
      mywindow.document.write(divContents);
      mywindow.document.write("</body></html>");
      mywindow.document.close();
      mywindow.focus();
      setTimeout(() => {
        mywindow.print();
        mywindow.close();
        return true;
      }, 1000);
    },

    closeSummary() {
      this.$store.commit("admin/clearNewStaff");
      this.$router.push({ name: "New Staff Bio Data" });
    },
  },
};
</script>

<style scoped>
.section {
  @apply bg-litergray rounded-lg p-6 mt-7;
}
.section > div:not(:first-child) {
  @apply mt-6;
}
a.button,
.section button[type="button"],
.section button[type="submit"] {
  @apply w-full  font-semibold py-2.5 rounded-lg flex justify-center items-center gap-2;
}
</style>
