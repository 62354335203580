<template>
  <div>
    <section class="text-idBlued">
      <h5>SECTION D:</h5>
      <h2>NEW STAFF EMPLOYMENT DATA</h2>
    </section>

    <form @submit.prevent="proceed">
      <div>
        <label for="dateOfAppointment">
          Date of 1st Appointment<span class="req">*</span>
        </label>
        <input
          id="dateOfAppointment"
          type="date"
          v-model="form.dateOfAppointment"
          class="input"
        />
      </div>

      <div>
        <label for="cadre">Cadre<span class="req">*</span> </label>
        <v-select
          id="cadre"
          label="cadreName"
          :reduce="(b) => b.cadreName"
          :options="$store.state.staff.cadres"
          v-model="form.cadre"
          placeholder="-- select --"
        ></v-select>
      </div>

      <div>
        <label for="salaryGradeLevel"
          >Salary Grade Level<span class="req">*</span>
        </label>
        <v-select
          id="salaryGradeLevel"
          v-model="form.salaryGradeLevel"
          label="grade"
          :reduce="(b) => b.grade"
          :options="$store.state.staff.salaryGradeLevels"
          placeholder="-- select --"
        ></v-select>
      </div>

      <div>
        <label for="step">Step<span class="req">*</span> </label>
        <v-select
          id="step"
          v-model="form.currentStep"
          label="step"
          :reduce="(b) => b.step"
          :options="$store.state.staff.gradeSteps"
          placeholder="-- select --"
        ></v-select>
      </div>

      <div>
        <label for="mdaName">MDA<span class="req">*</span> </label>
        <v-select
          id="mdaName"
          label="name"
          :reduce="(b) => b._id"
          :options="$store.state.staff.mdas"
          v-model="form.mdaId"
          placeholder="-- select --"
        ></v-select>
      </div>

      <div>
        <label for="departmentName">
          Department<span class="req">*</span>
        </label>
        <v-select
          id="departmentName"
          label="departmentName"
          :reduce="(b) => b._id"
          :options="departments"
          v-model="form.departmentId"
          :filterable="false"
          @search="searchDepartments"
          placeholder="-- select departments --"
        >
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              No results found for <em>{{ search }}</em
              >.
            </template>
            <span v-else clas="text-gray-400 lowercase text-xs">
              Start typing to search for your department
            </span>
          </template>
        </v-select>
      </div>

      <div>
        <label for="staffType">Staff Type<span class="req">*</span></label>
        <v-select
          id="staffType"
          label="name"
          :reduce="(b) => b._id"
          :options="$store.state.admin.staffTypes"
          v-model="form.staffTypeId"
          uid="_id"
          placeholder="-- select --"
        ></v-select>
      </div>

      <div class="flex justify-between gap-4">
        <button
          type="button"
          class="bg-gray-600"
          @click="$router.push({ name: 'New Staff Bio Data' })"
        >
          <span>Previous</span>
        </button>

        <button
          :disabled="proceeding || !formReady"
          type="submit"
          class="bg-oneGreen"
        >
          <span>Save and Proceed</span>
          <spinner v-if="proceeding"></spinner>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { fetchDepartments } from "@/services/sourceData";
export default {
  name: "NewStaffEmploymentData",
  data() {
    return {
      proceeding: false,
      form: {
        dateOfAppointment: this.$store.state.admin?.newStaff?.dateOfAppointment,
        cadre: this.$store.state.admin?.newStaff?.cadre,
        salaryGradeLevel: this.$store.state.admin?.newStaff?.salaryGradeLevel,
        currentStep: this.$store.state.admin?.newStaff?.currentStep,
        mdaId: this.$store.state.admin?.newStaff?.mdaId,
        mdaName: this.$store.state.admin?.newStaff?.mdaName,
        departmentId: this.$store.state.admin?.newStaff?.departmentId,
        departmentName: this.$store.state.admin?.newStaff?.departmentName,
        staffTypeId: this.$store.state.admin?.newStaff?.staffTypeId,
        staffTypeName: this.$store.state.admin?.newStaff?.staffTypeName,
      },
      departments: [],
    };
  },

  async created() {
    await this.$store.dispatch("staff/getCadres");
    await this.$store.dispatch("staff/getSalaryGradeLevels");
    await this.$store.dispatch("staff/getGradeSteps");
  },

  watch: {
    "form.departmentId"(newValue) {
      const dt = this.departments.find(({ _id }) => _id === newValue);
      this.form.departmentName = dt ? dt.departmentName : null;
    },
    "form.mdaId"(newValue) {
      const dt = this.$store.state.staff.mdas.find(
        ({ _id }) => _id === newValue
      );
      this.form.mdaName = dt ? dt.name : null;
    },
    "form.staffTypeId"(newValue) {
      const dt = this.$store.state.admin.staffTypes.find(
        ({ _id }) => _id === newValue
      );
      this.form.staffTypeName = dt ? dt.name : null;
    },
  },

  computed: {
    formReady() {
      let ready = true;

      for (const fd in this.form) {
        if (Object.hasOwnProperty.call(this.form, fd)) {
          if (
            !this.form[fd] ||
            (typeof this.form[fd] === "string" && this.form[fd].trim() === "")
          ) {
            ready = false;
          }
        }
      }

      return ready;
    },
  },

  methods: {
    async searchDepartments(search, loading) {
      if (search.length < 3) {
        return;
      }

      try {
        loading(true);
        this.departments = await fetchDepartments(search);
        loading(false);
      } catch (err) {
        loading(false);
      }
    },

    async proceed() {
      if (!this.formReady) {
        this.$swal({
          icon: "error",
          text: "Fill all fiields",
        });
        return;
      }

      try {
        this.proceeding = true;

        this.$store.commit("admin/saveNewStaff", this.form);

        this.proceeding = false;

        this.$router.push({ name: "New Staff Bank Verification" });
      } catch (error) {
        this.proceeding = false;
      }
    },
  },
};
</script>

<style scoped>
h5 {
  @apply text-xs lg:text-base font-bold uppercase;
}
h2 {
  @apply text-xl lg:text-3pxl uppercase;
}
form {
  @apply bg-litergray rounded-lg p-6 mt-7;
}
form > div:not(:first-child) {
  @apply mt-6;
}
.req {
  @apply text-red-400;
}
form button[type="button"],
form button[type="submit"] {
  @apply w-full text-white font-semibold py-2.5 rounded-lg flex justify-center items-center gap-2;
}
input {
  @apply uppercase;
}
</style>
